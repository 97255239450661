import axios from "axios";
import isURL from "validator/lib/isURL";

export const BaseService = axios.create({
    baseURL: "https://api.megnus.app/dev",
});

interface IResponse<T> {
    status: number;
    message: string;
    data: T;
}

BaseService.interceptors.request.use(
    (config) => {
        const accessToken = localStorage.getItem("access_token");

        if (config.headers) {
            if (accessToken)
                config.headers["Authorization"] = `Bearer ${accessToken}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

BaseService.interceptors.response.use(
    (response) => response,
    async (error) => {
        const { response, request } = error;
        if (!!response === false) return Promise.reject(error);

        const statusCode = parseInt(response.data.status || "500");
        const message = response.data.message;
        const requestPathname = isURL(request.responseURL, { require_tld: false })
            ? new URL(request.responseURL).pathname
            : null;

        if (
            statusCode === 401 &&
            requestPathname !== null &&
            requestPathname !== "/dev/auth/refresh"
        ) {
            try {
                const accessToken = localStorage.getItem("access_token");

                const refreshResponse = await BaseService.post(`/auth/refresh`, null, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                });
                const refreshResponseData: IResponse<{ token: string }> =
                    refreshResponse.data;
                const newAccessToken = refreshResponseData.data.token;

                localStorage.setItem("access_token", newAccessToken);

                // Retry the original request with the new token
                error.config.headers["Authorization"] = `Bearer ${newAccessToken}`;
                return BaseService.request(error.config);
            } catch (error) {
                return Promise.reject(error);
            }
        } else if (statusCode === 401) {
            // logout user
            localStorage.removeItem("access_token");
            return Promise.reject(error);
        }

        return Promise.reject(error);
    }
);
