import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { feedbackReducer, SET_ROUTE, SET_TOKEN } from "../redux/reducer";
import logo from "../assets/logo.png";
import axios from "axios";
import { RootState } from "../redux/store";
import { useIsFirstRender } from "usehooks-ts";
import Cookies from "js-cookie";

const LoginPage = () => {
  const isFirst = useIsFirstRender();

  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [checked, setChecked] = useState<boolean>(false);
  const [failedSignIn, setFailedSignIn] = useState<boolean>(false);

  const dispatch = useDispatch();

  const feedbackStates = useSelector(
    (state: RootState) => state.feedbackReducer
  );

  const saveTokenToCookie = (token: string) => {
    Cookies.set("token", token, { expires: 7 });
  };

  const handleClick = () => {
    axios
      .post(
        "https://api.megnus.app/dev/auth/signin",
        { userName: userName, password: password }
      )
      .then((res) => {
        console.log("Res", res);
        dispatch(SET_TOKEN(res.data.data.token));
        localStorage.setItem("access_token", res.data.data.token);
        localStorage.setItem("refresh_token", res.data.data.refreshToken);
        saveTokenToCookie(res.data.data.token);
        console.log(res.data.data);
      })
      .catch((err) => {
        console.error(err)
        setFailedSignIn(true);
      });
  };

  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      dispatch(SET_TOKEN(token));
    }
  }, []);

  useEffect(() => {
    if (!isFirst) {
      dispatch(SET_ROUTE("/FeedbackPage"));
    }
  }, [feedbackStates.token]);

  return (
    <div
      className={`bg-[#645DBC] h-screen flex items-start  justify-center select-none`}
    >
      <div className="flex-cols gap-5 justify-center pt-[60px] w-full md:w-auto">
        <div className="pb-[50px] w-[50px] h-[50px] flex justify-center">
        </div>
        <div className="mt-[80px] p-[30px] bg-white md:rounded-[12px] w-full md:w-auto">
          <div className="font-[700] text-[24px] flex justify-center py-[10px]">
            Login to Your Account
          </div>
          <div className="py-[10px] w-full">
            <TextField
              autoFocus
              onChange={(e) => setUserName(e.target.value)}
              className="w-full md:w-[350px] bg-[#E8F0FE]"
              id="outlined-basic"
              label="Username"
              variant="outlined"
            />
          </div>
          <div className="py-[10px] w-full">
            <TextField
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleClick();
                }
              }}
              className="w-full md:w-[350px] bg-[#E8F0FE]"
              id="outlined-basic"
              label="Password"
              type="password"
              variant="outlined"
              placeholder="Enter 5 digit Pin"
            />
          </div>
          <div
            className={`${failedSignIn ? "text-[12px] text-red-600" : "hidden"
              } `}
          >
            Username or Password is incorrect
          </div>
          <div className="py-[10px] flex justify-between items-center font-[600] text-[11px]">
            <div className="flex items-center justify-start text-[#111827]">
              <div>
                <Checkbox
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
              </div>
              <div
                className="cursor-pointer"
                onClick={() => setChecked(!checked)}
              >
                Remember me
              </div>
            </div>
            <div className={`text-[#645DBC] cursor-pointer`}>
              Forgot Password
            </div>
          </div>
          <div
            className={`py-[10px] text-[#645DBC] w-full rounded-[6px] text-center cursor-pointer font-[600] text-[16px]`}
            onClick={() => {
              handleClick();
            }}
          >
            Sign In
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
