import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import emoji1 from "../assets/emoji1.png";
import watermark from "../assets/watermark.png";
import emoji2 from "../assets/emoji2.png";
import emoji3 from "../assets/emoji3.png";
import emoji4 from "../assets/emoji4.png";
import emoji5 from "../assets/emoji5.png";
import contactInfo from "../assets/contactInfo.svg";
import Thankyou from "../assets/Thankyou.png";
import { RootState } from "../redux/store";
import { MdRestartAlt } from "react-icons/md";
import { AiFillStar } from "react-icons/ai";
import { BiChevronLeft } from "react-icons/bi";
import {
  SET_REASONS,
  SET_ITERATOR,
  SET_QUESTION_ANSWERS,
  SET_DATA,
  SET_DARK,
  SET_THEMECOLOR,
  SET_ROUTE,
  SET_USER_RESPONSE,
  SET_TOKEN,
} from "../redux/reducer";
import { BaseService } from "../assets/BaseService";
import "./animation.css";
import { useMediaQuery } from "usehooks-ts";
import { useScreen } from "usehooks-ts";
import Cookies from "js-cookie";
import { Button } from "@mui/material";

interface FeedbackPageProps {
  onLogout: () => void;
}

const FeedbackPage: React.FC<FeedbackPageProps> = ({ onLogout }) => {
  const dispatch = useDispatch();

  const data = useSelector((state: RootState) => state.feedbackReducer.data);
  const feedBackStates = useSelector(
    (state: RootState) => state.feedbackReducer
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isReasonOpen, setIsReasonOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [hoveringOver, setHoveringOver] = useState<number>(0);
  const [isSubmitPage, setIsSubmitPage] = useState<boolean>(false);
  const [isRemarksPage, setIsRemarksPage] = useState<boolean>(false);
  const [isThankYouPage, setIsThankYouPage] = useState<boolean>(false);
  const [isAnimation, setIsAnimation] = useState<boolean>(true);

  const [name, setName] = useState<string>("");
  const [number, setNumber] = useState<number | null>(null);
  const [email, setEmail] = useState<string>("");

  const matches = useMediaQuery("(min-width: 1024px)");
  const screen = useScreen();

  const checkAndRefreshToken = async () => {
    const token = localStorage.getItem("access_token");
    if (!token) {
      dispatch(SET_ROUTE("/"));
      return;
    }

    try {
      await BaseService.get("/auth/verify");
    } catch (error) {
      console.log("Token refreshed or user logged out");
    }
  };

  const API = async () => {
    await checkAndRefreshToken();
    await BaseService.post("/dynamic/get", {
      collection: "feedback-app",
    })
      .then((res: any) => {
        console.log("Response from GET", res);
        console.log(res.data.data);
        dispatch(SET_DATA(res.data.data[0]));
        dispatch(SET_DARK(res.data.data[0].themeMode));
        dispatch(SET_THEMECOLOR(res.data.data[0].themeColor));

        if (res.data.data.length !== 0) setIsLoading(false);
      })
      .catch((err: any) => {
        console.log("Error: ", err);
        if (err) setIsLoading(true);
      });
  };

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
    dispatch(
      SET_REASONS({
        reason: event.target.value,
        index: feedBackStates.iterator,
      })
    );
    setIsReasonOpen(false);
    handleIncrement();
  };

  const handleIncrement = () => {
    if (data && data.questions.length > feedBackStates.iterator + 1) {
      dispatch(SET_ITERATOR(feedBackStates.iterator + 1));
      setIsAnimation(true);
    } else {
      setIsAnimation(true);
      setIsRemarksPage(true);
    }
  };

  const handleSubmit = async () => {
    await checkAndRefreshToken();
    const currentDate = new Date().toISOString();
    const updatedResponse = {
      ...feedBackStates.userResponse,
      name,
      number,
      email,
      createdAt: currentDate,
      updatedAt: currentDate,
    };
    await BaseService.post("/dynamic/create", {
      collection: "feedback",
      data: updatedResponse,
    }).then((res: any) => {
      console.log(res.data);
      if (res.data.status === 201) {
        setIsThankYouPage(true);
        setTimeout(() => {
          setIsAnimation(true);
          dispatch(SET_ITERATOR(0));
          setIsRemarksPage(false);
          setIsSubmitPage(false);
          setIsReasonOpen(false);
          setIsThankYouPage(false);
          setName("");
          setNumber(null);
          setEmail("");
        }, 5000);
      }
    });
  };

  useEffect(() => {
    API();
  }, []);

  return (
    <div className="w-full h-full flex items-center justify-center overflow-hidden">
      <img
        className={`${
          matches === true
            ? "object-fill top-0 left-0 right-0 bottom-0"
            : "object-cover"
        } -z-10 w-full h-screen fixed`}
        src={
          data === null
            ? undefined
            : data && data.background[0] && data.background[0].url
        }
        alt=""
      />

      <div className="fixed top-4 right-4 z-20">
        <Button
          variant="contained"
          color="secondary"
          onClick={onLogout}
          className="px-4 py-2 text-sm font-medium rounded-md shadow-md hover:shadow-lg transition-shadow duration-300"
        >
          Logout
        </Button>
      </div>

      <div className="fixed top-0 left-0 z-10 w-full  flex items-center justify-start h-[70px] px-[15px] md:h-[150px] md:px-[60px]">
        <img
          className={`w-[${data && parseInt(data.logoWidth) / 1.5}px] h-[${
            data && parseInt(data.logoHeight) / 1.5
          }px] md:w-[${data && data.logoWidth}px] md:h-[${
            data && data.logoHeight
          }px]`}
          src={
            data === null ? undefined : data && data.logo[0] && data.logo[0].url
          }
          alt=""
        />
      </div>

      <div
        className={`fixed top-[162px] lg:top-[36px] left-[${
          screen && screen.width / 2 - (screen.width - 32) / 2 - 16
        }px] lg:left-[calc(100%-280px)] z-5 w-[38px] h-[38px] bg-[${
          feedBackStates.themeColor
        }] rounded-full flex items-center justify-center text-[12px] font-bold translate-x-[${
          data !== null && isSubmitPage === false
            ? `${
                screen &&
                (feedBackStates.iterator / (data.questions.length + 1)) *
                  (screen.width - 32)
              }px`
            : data !== null && isSubmitPage === true && isThankYouPage === false
            ? `${
                screen &&
                ((feedBackStates.iterator + 1) / (data.questions.length + 1)) *
                  (screen.width - 32)
              }px`
            : data !== null &&
              isSubmitPage === true &&
              isThankYouPage === true &&
              `${
                screen &&
                ((feedBackStates.iterator + 2) / (data.questions.length + 1)) *
                  (screen.width - 32)
              }px`
        }]  lg:translate-x-[${
          data !== null && isSubmitPage === false
            ? `${
                (feedBackStates.iterator / (data.questions.length + 1)) * 200
              }px`
            : data !== null && isSubmitPage === true && isThankYouPage === false
            ? `${
                ((feedBackStates.iterator + 1) / (data.questions.length + 1)) *
                200
              }px`
            : data !== null &&
              isSubmitPage === true &&
              isThankYouPage === true &&
              `${
                ((feedBackStates.iterator + 2) / (data.questions.length + 1)) *
                200
              }px`
        }] transition duration-500 ease-in-out`}
      >
        {data !== null && isSubmitPage === false
          ? `${Math.round(
              (feedBackStates.iterator / (data.questions.length + 1)) * 100
            )}%`
          : data !== null && isSubmitPage === true && isThankYouPage === false
          ? `${Math.round(
              ((feedBackStates.iterator + 1) / (data.questions.length + 1)) *
                100
            )}%`
          : data !== null &&
            isSubmitPage === true &&
            isThankYouPage === true &&
            `${Math.round(
              ((feedBackStates.iterator + 2) / (data.questions.length + 1)) *
                100
            )}%`}
      </div>

      <div
        className={`fixed top-[200px] left-[${
          screen && screen.width / 2 - (screen.width - 32) / 2
        }px] lg:top-[75px] lg:left-[calc(100%-260px)] z-5 w-[${
          screen && screen.width - 32
        }px] lg:left-[calc(100%-260px)] z-10 ${
          data !== null && isSubmitPage === false
            ? `w-[${
                screen &&
                (feedBackStates.iterator / (data.questions.length + 1)) *
                  (screen.width - 32)
              }px] lg:w-[${
                (feedBackStates.iterator / (data.questions.length + 1)) * 200
              }px]`
            : data !== null && isSubmitPage === true && isThankYouPage === false
            ? `w-[${
                screen &&
                ((feedBackStates.iterator + 1) / (data.questions.length + 1)) *
                  (screen.width - 32)
              }px] lg:w-[${
                ((feedBackStates.iterator + 1) / (data.questions.length + 1)) *
                200
              }px]`
            : data !== null &&
              isSubmitPage === true &&
              isThankYouPage === true &&
              `w-[${
                screen &&
                ((feedBackStates.iterator + 2) / (data.questions.length + 1)) *
                  (screen.width - 32)
              }px] lg:w-[${
                ((feedBackStates.iterator + 2) / (data.questions.length + 1)) *
                200
              }px]`
        }   h-[7px] bg-[${
          feedBackStates.themeColor
        }] rounded-full transition-width duration-500 ease-in-out`}
      ></div>
      <div
        className={`fixed top-[200px] left-[${
          screen && screen.width / 2 - (screen.width - 32) / 2
        }px] lg:top-[75px] lg:left-[calc(100%-260px)] z-5 w-[${
          screen && screen.width - 32
        }px] lg:w-[200px] h-[7px] bg-slate-200 rounded-full`}
      ></div>

      {isThankYouPage === false ? (
        <div>
          {isSubmitPage === false ? (
            <div>
              {isRemarksPage === false ? (
                <div>
                  {isReasonOpen === true ? (
                    <div
                      onAnimationEnd={() => setIsAnimation(false)}
                      className={`${
                        feedBackStates.dark === "dark" ? "bg-black" : "bg-white"
                      } rounded-[16px] md:rounded-[24px] w-[${
                        screen &&
                        screen.width !== undefined &&
                        screen.width - 32
                      }px] p-[16px] h-[254px] md:w-[646px] md:h-[353px] md:p-[40px] flex flex-col items-center lg:items-start justify-start ${
                        isAnimation === true && "animated-div"
                      }`}
                    >
                      <div className="flex items-center justify-center lg:justify-start lg:gap-[18px] flex-col lg:flex-row">
                        <img
                          className="w-[84px] h-[84px]"
                          src={
                            data === null
                              ? undefined
                              : data.questions &&
                                data.questions[feedBackStates.iterator]
                                  .icon[0] &&
                                data.questions[feedBackStates.iterator].icon[0]
                                  .url
                          }
                          alt=""
                        />
                        <div className="w-full">
                          <h2
                            className={`text-[20px] text-center lg:text-[32px] ${
                              feedBackStates.dark === "dark"
                                ? "text-white"
                                : "text-black"
                            } font-bold lg:text-left`}
                          >
                            {data &&
                              data.questions &&
                              data.questions[feedBackStates.iterator].title}
                          </h2>
                          <h4
                            className={`${
                              feedBackStates.dark === "dark"
                                ? "text-white"
                                : "text-black"
                            } text-[14px] text-center lg:text-[20px] max-w-[296px] lg:max-w-[420px] truncate whitespace-nowrap lg:whitespace-normal lg:text-left`}
                          >
                            {data &&
                              data.questions &&
                              data.questions[feedBackStates.iterator].question}
                          </h4>
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          setIsReasonOpen(false);
                          setIsAnimation(true);
                        }}
                        className={`mt-[10px] lg:mt-[35px] bg-[${feedBackStates.themeColor}] border-[1px] h-[32px] py-[8px] lg:py-0 lg:h-[36px] px-[12px] border-slate-300 cursor-pointer rounded-full flex items-center justify-center gap-1 transition duration-300 ease-in-out hover:scale-110`}
                      >
                        <BiChevronLeft
                          size={screen && screen.width < 600 ? 20 : 25}
                        />
                        <h3 className="text-[13px] lg:text-[20px]">
                          Change your feedback
                        </h3>
                      </div>

                      <div className="flex items-center justify-around w-full mt-[20px] lg:mt-[42px]">
                        {data &&
                          data.questions &&
                          data.questions[feedBackStates.iterator].reasons &&
                          data.questions[feedBackStates.iterator].reasons.map(
                            (option: any) => (
                              <label
                                className={`${
                                  feedBackStates.dark === "dark"
                                    ? "text-white"
                                    : "text-black"
                                } text-[13px] lg:text-[24px] flex items-center justify-between cursor-pointer transition duration-300 ease-in-out hover:scale-110`}
                                key={option.label}
                              >
                                <input
                                  className="mr-1 lg:mr-2 w-[16px] h-[16px] lg:w-[20px] lg:h-[20px]"
                                  size={screen && screen.width < 600 ? 16 : 20}
                                  type="radio"
                                  name="option"
                                  value={option.value}
                                  checked={selectedOption === option.value}
                                  onChange={handleOptionChange}
                                />
                                {option.label}
                              </label>
                            )
                          )}
                      </div>
                    </div>
                  ) : (
                    <div
                      onAnimationEnd={() => setIsAnimation(false)}
                      className={`${
                        feedBackStates.dark === "dark" ? "bg-black" : "bg-white"
                      } rounded-[16px] md:rounded-[24px] w-[${
                        screen &&
                        screen.width !== undefined &&
                        screen.width - 32
                      }px] h-[254px] p-[16px] md:w-[646px] md:h-[353px] md:p-[40px] flex flex-col items-center lg:items-start justify-start ${
                        isAnimation === true && "animated-div"
                      }`}
                    >
                      <div className="flex items-center justify-center lg:justify-start lg:gap-[18px] flex-col lg:flex-row">
                        <img
                          className="w-[84px] h-[84px]"
                          src={
                            data === null
                              ? undefined
                              : data &&
                                data.questions &&
                                data.questions[feedBackStates.iterator]
                                  .icon[0] &&
                                data.questions[feedBackStates.iterator].icon[0]
                                  .url
                          }
                          alt=""
                        />
                        <div className="w-full">
                          <h2
                            className={`text-[20px] text-center lg:text-[32px] ${
                              feedBackStates.dark === "dark"
                                ? "text-white"
                                : "text-black"
                            } font-bold lg:text-left`}
                          >
                            {data &&
                              data.questions &&
                              data.questions[feedBackStates.iterator].title}
                          </h2>
                          <h4
                            className={`${
                              feedBackStates.dark === "dark"
                                ? "text-white"
                                : "text-black"
                            } text-[14px] text-center lg:text-[20px] max-w-[296px] lg:max-w-[420px] truncate whitespace-nowrap lg:whitespace-normal lg:text-left`}
                          >
                            {data &&
                              data.questions &&
                              data.questions[feedBackStates.iterator].question}
                          </h4>
                        </div>
                      </div>
                      {data &&
                        data.questions &&
                        data.questions[feedBackStates.iterator].type ===
                          "stars" && (
                          <div className="mt-[10px] lg:mt-[85px] flex items-center justify-between w-full">
                            <div
                              onClick={() => {
                                setIsReasonOpen(true);
                                setIsAnimation(true);
                                dispatch(
                                  SET_QUESTION_ANSWERS({
                                    question:
                                      data.questions[feedBackStates.iterator]
                                        .title,
                                    answer: "Very Bad",
                                    index: feedBackStates.iterator,
                                  })
                                );
                              }}
                              onMouseEnter={() => setHoveringOver(1)}
                              onMouseLeave={() => setHoveringOver(0)}
                              className={`w-[59px] h-[75px] lg:w-[100px] lg:h-[100px] flex flex-col items-center justify-center cursor-pointer gap-[16px] transition duration-300 ease-in-out hover:scale-110`}
                            >
                              <AiFillStar
                                className={`${
                                  hoveringOver > 0
                                    ? "text-yellow-500"
                                    : "text-slate-200"
                                } transition-all duration-300 ease-in-out`}
                                size={screen && screen.width < 600 ? 26 : 38}
                              />
                              <h2
                                className={`text-[13px] lg:text-[20px] ${
                                  feedBackStates.dark === "dark"
                                    ? "text-white"
                                    : "text-black"
                                }`}
                              >
                                Very Bad
                              </h2>
                            </div>
                            <div
                              onClick={() => {
                                setIsReasonOpen(true);
                                setIsAnimation(true);
                                dispatch(
                                  SET_QUESTION_ANSWERS({
                                    question:
                                      data.questions[feedBackStates.iterator]
                                        .title,
                                    answer: "Bad",
                                    index: feedBackStates.iterator,
                                  })
                                );
                              }}
                              onMouseEnter={() => setHoveringOver(2)}
                              onMouseLeave={() => setHoveringOver(0)}
                              className={`w-[59px] h-[75px] lg:w-[100px] lg:h-[100px] rounded-[6px] flex flex-col gap-[16px] items-center justify-center cursor-pointer transition-all duration-300 ease-in-out hover:scale-110`}
                            >
                              <AiFillStar
                                className={`${
                                  hoveringOver > 1
                                    ? "text-yellow-500"
                                    : "text-slate-200"
                                } transition-all duration-300 ease-in-out`}
                                size={screen && screen.width < 600 ? 26 : 38}
                              />
                              <h2
                                className={`text-[13px] lg:text-[20px] ${
                                  feedBackStates.dark === "dark"
                                    ? "text-white"
                                    : "text-black"
                                }`}
                              >
                                Bad
                              </h2>
                            </div>
                            <div
                              onClick={() => {
                                handleIncrement();
                                dispatch(
                                  SET_QUESTION_ANSWERS({
                                    question:
                                      data.questions[feedBackStates.iterator]
                                        .title,
                                    answer: "Average",
                                    index: feedBackStates.iterator,
                                  })
                                );
                              }}
                              onMouseEnter={() => setHoveringOver(3)}
                              onMouseLeave={() => setHoveringOver(0)}
                              className={`w-[59px] h-[75px] lg:w-[100px] lg:h-[100px] rounded-[6px] flex flex-col gap-[16px] items-center justify-center cursor-pointer transition-all duration-300 ease-in-out hover:scale-110`}
                            >
                              <AiFillStar
                                className={`${
                                  hoveringOver > 2
                                    ? "text-yellow-500"
                                    : "text-slate-200"
                                } transition-all duration-300 ease-in-out`}
                                size={screen && screen.width < 600 ? 26 : 38}
                              />
                              <h2
                                className={`text-[13px] lg:text-[20px] ${
                                  feedBackStates.dark === "dark"
                                    ? "text-white"
                                    : "text-black"
                                }`}
                              >
                                Average
                              </h2>
                            </div>
                            <div
                              onClick={() => {
                                handleIncrement();
                                dispatch(
                                  SET_QUESTION_ANSWERS({
                                    question:
                                      data.questions[feedBackStates.iterator]
                                        .title,
                                    answer: "4 Star",
                                    index: feedBackStates.iterator,
                                  })
                                );
                              }}
                              onMouseEnter={() => setHoveringOver(4)}
                              onMouseLeave={() => setHoveringOver(0)}
                              className={`w-[59px] h-[75px] lg:w-[100px] lg:h-[100px] rounded-[6px] flex flex-col gap-[16px] items-center justify-center cursor-pointer transition-all duration-300 ease-in-out hover:scale-110`}
                            >
                              <AiFillStar
                                className={`${
                                  hoveringOver > 3
                                    ? "text-yellow-500"
                                    : "text-slate-200"
                                } transition-all duration-300 ease-in-out`}
                                size={screen && screen.width < 600 ? 26 : 38}
                              />
                              <h2
                                className={`text-[13px] lg:text-[20px] ${
                                  feedBackStates.dark === "dark"
                                    ? "text-white"
                                    : "text-black"
                                }`}
                              >
                                Good
                              </h2>
                            </div>
                            <div
                              onClick={() => {
                                handleIncrement();
                                dispatch(
                                  SET_QUESTION_ANSWERS({
                                    question:
                                      data.questions[feedBackStates.iterator]
                                        .title,
                                    answer: "5 Star",
                                    index: feedBackStates.iterator,
                                  })
                                );
                              }}
                              onMouseEnter={() => setHoveringOver(5)}
                              onMouseLeave={() => setHoveringOver(0)}
                              className={`w-[59px] h-[75px] lg:w-[100px] lg:h-[100px] rounded-[6px] flex flex-col gap-[16px] items-center justify-center cursor-pointer transition-all duration-300 ease-in-out hover:scale-110`}
                            >
                              <AiFillStar
                                className={`${
                                  hoveringOver > 4
                                    ? "text-yellow-500"
                                    : "text-slate-200"
                                } transition-all duration-300 ease-in-out`}
                                size={screen && screen.width < 600 ? 26 : 38}
                              />
                              <h2
                                className={`text-[13px] lg:text-[20px] ${
                                  feedBackStates.dark === "dark"
                                    ? "text-white"
                                    : "text-black"
                                }`}
                              >
                                Excellent
                              </h2>
                            </div>
                          </div>
                        )}
                      {data &&
                        data.questions &&
                        data.questions[feedBackStates.iterator].type ===
                          "emoji" && (
                          <div className="mt-[10px] lg:mt-[85px] flex items-center justify-between w-full">
                            <div className="w-[59px] h-[75px] lg:w-[100px] lg:h-[100px] cursor-pointer transition duration-300 ease-in-out hover:scale-110 flex items-center justify-center flex-col">
                              <img
                                onClick={() => {
                                  setIsReasonOpen(true);
                                  setIsAnimation(true);
                                  dispatch(
                                    SET_QUESTION_ANSWERS({
                                      question:
                                        data.questions[feedBackStates.iterator]
                                          .title,
                                      answer: "Very Bad",
                                      index: feedBackStates.iterator,
                                    })
                                  );
                                }}
                                className="w-[30px] h-[30px] lg:w-[48px] lg:h-[48px]"
                                src={emoji1}
                                alt=""
                              />
                              <h2
                                className={`text-[13px] mt-[12px] lg:text-[20px] ${
                                  feedBackStates.dark === "dark"
                                    ? "text-white"
                                    : "text-black"
                                }`}
                              >
                                Very Bad
                              </h2>
                            </div>
                            <div className="w-[59px] h-[75px] lg:w-[100px] lg:h-[100px] cursor-pointer transition duration-300 ease-in-out hover:scale-110 flex items-center justify-center flex-col">
                              <img
                                onClick={() => {
                                  setIsReasonOpen(true);
                                  setIsAnimation(true);
                                  dispatch(
                                    SET_QUESTION_ANSWERS({
                                      question:
                                        data.questions[feedBackStates.iterator]
                                          .title,
                                      answer: "Bad",
                                      index: feedBackStates.iterator,
                                    })
                                  );
                                }}
                                className="w-[30px] h-[30px] lg:w-[48px] lg:h-[48px]"
                                src={emoji2}
                                alt=""
                              />
                              <h2
                                className={`text-[13px] mt-[12px] lg:text-[20px] ${
                                  feedBackStates.dark === "dark"
                                    ? "text-white"
                                    : "text-black"
                                }`}
                              >
                                Bad
                              </h2>
                            </div>
                            <div className="w-[59px] h-[75px] lg:w-[100px] lg:h-[100px] cursor-pointer transition duration-300 ease-in-out hover:scale-110 flex items-center justify-center flex-col">
                              <img
                                className="w-[30px] h-[30px] lg:w-[48px] lg:h-[48px]"
                                src={emoji3}
                                alt=""
                                onClick={() => {
                                  handleIncrement();
                                  dispatch(
                                    SET_QUESTION_ANSWERS({
                                      question:
                                        data.questions[feedBackStates.iterator]
                                          .title,
                                      answer: "Average",
                                      index: feedBackStates.iterator,
                                    })
                                  );
                                }}
                              />
                              <h2
                                className={`text-[13px] mt-[12px] lg:text-[20px] ${
                                  feedBackStates.dark === "dark"
                                    ? "text-white"
                                    : "text-black"
                                }`}
                              >
                                Average
                              </h2>
                            </div>
                            <div className="w-[59px] h-[75px] lg:w-[100px] lg:h-[100px] cursor-pointer transition duration-300 ease-in-out hover:scale-110 flex items-center justify-center flex-col">
                              <img
                                className="w-[30px] h-[30px] lg:w-[48px] lg:h-[48px]"
                                src={emoji4}
                                alt=""
                                onClick={() => {
                                  handleIncrement();
                                  dispatch(
                                    SET_QUESTION_ANSWERS({
                                      question:
                                        data.questions[feedBackStates.iterator]
                                          .title,
                                      answer: "Good",
                                      index: feedBackStates.iterator,
                                    })
                                  );
                                }}
                              />
                              <h2
                                className={`text-[13px] mt-[12px] lg:text-[20px] ${
                                  feedBackStates.dark === "dark"
                                    ? "text-white"
                                    : "text-black"
                                }`}
                              >
                                Good
                              </h2>
                            </div>
                            <div className="w-[59px] h-[75px] lg:w-[100px] lg:h-[100px] cursor-pointer transition duration-300 ease-in-out hover:scale-110 flex items-center justify-center flex-col">
                              <img
                                className="w-[30px] h-[30px] lg:w-[48px] lg:h-[48px]"
                                src={emoji5}
                                alt=""
                                onClick={() => {
                                  handleIncrement();
                                  dispatch(
                                    SET_QUESTION_ANSWERS({
                                      question:
                                        data.questions[feedBackStates.iterator]
                                          .title,
                                      answer: "Excellent",
                                      index: feedBackStates.iterator,
                                    })
                                  );
                                }}
                              />
                              <h2
                                className={`text-[13px] mt-[12px] lg:text-[20px] ${
                                  feedBackStates.dark === "dark"
                                    ? "text-white"
                                    : "text-black"
                                }`}
                              >
                                Excellent
                              </h2>
                            </div>
                          </div>
                        )}
                    </div>
                  )}
                </div>
              ) : (
                <div
                  onAnimationEnd={() => setIsAnimation(false)}
                  className={`${
                    feedBackStates.dark === "dark" ? "bg-black" : "bg-white"
                  } rounded-[16px] md:rounded-[24px] w-[${
                    screen && screen.width !== undefined && screen.width - 32
                  }px] h-[275px] md:w-[646px] md:h-[353px] md:p-[40px] flex flex-col items-center lg:items-start justify-start ${
                    isAnimation === true && "animated-div"
                  }`}
                >
                  <div className="flex items-center justify-center lg:justify-start lg:gap-[18px] flex-col lg:flex-row">
                    <img
                      className="w-[84px] h-[84px]"
                      src={contactInfo}
                      alt=""
                    />
                    <div>
                      <h2
                        className={`${
                          feedBackStates.dark === "dark"
                            ? "text-white"
                            : "text-black"
                        } text-[20px] text-center lg:text-[32px] font-bold lg:text-left`}
                      >
                        Contact Information
                      </h2>
                      <h4
                        className={`${
                          feedBackStates.dark === "dark"
                            ? "text-white"
                            : "text-black"
                        } text-[14px] text-center lg:text-[20px] max-w-[296px] lg:max-w-[420px] truncate whitespace-nowrap lg:whitespace-normal lg:text-left`}
                      >
                        We would like to know your contact information
                      </h4>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setIsRemarksPage(false);
                      setIsAnimation(true);
                    }}
                    className={`mt-[10px] bg-[${feedBackStates.themeColor}] lg:mt-[35px] border-[1px] h-[32px] py-[8px] lg:py-0 lg:h-[36px] px-[12px] border-slate-300 cursor-pointer rounded-full flex items-center justify-center gap-1 transition duration-300 ease-in-out hover:scale-110`}
                  >
                    <BiChevronLeft
                      size={screen && screen.width < 600 ? 20 : 25}
                    />
                    <h3 className="text-[13px] lg:text-[20px]">
                      Change your feedback
                    </h3>
                  </div>

                  <div className="flex items-center justify-between px-[16px] lg:px-0 w-full mt-[10px] lg:mt-[24px]">
                    <input
                      className={`${
                        feedBackStates.dark === "dark"
                          ? "bg-gray-700"
                          : "bg-white"
                      } border-[1px] text-[13px] placeholder:text-[13px] lg:text-[20px] lg:placeholder:text-[20px] border-slate-200 w-[48%] h-[32px] lg:h-[53px] px-[8px] lg:px-[24px] py-[4px] lg:py-[16px] rounded-[6px] transition duration-300 ease-in-out hover:scale-110`}
                      placeholder="Order ID"
                      type="text"
                    />
                    <input
                      className={`${
                        feedBackStates.dark === "dark"
                          ? "bg-gray-700"
                          : "bg-white"
                      } border-[1px] text-[13px] placeholder:text-[13px] lg:text-[20px] lg:placeholder:text-[20px] border-slate-200 w-[48%] h-[32px] lg:h-[53px] px-[8px] lg:px-[24px] py-[4px] lg:py-[16px] rounded-[6px] transition duration-300 ease-in-out hover:scale-110`}
                      placeholder="Remarks"
                      type="text"
                    />
                  </div>
                  <button
                    onClick={() => {
                      setIsSubmitPage(true);
                      setIsAnimation(true);
                    }}
                    className={`${
                      feedBackStates.dark === "dark"
                        ? `bg-[${feedBackStates.themeColor}] text-black`
                        : "bg-black text-white"
                    } flex items-center justify-center rounded-[6px] cursor-pointer w-[calc(100%-32px)] lg:w-[100px] h-[32px] lg:h-[40px] px-[24px] py-[8px] mt-[12px] lg:mt-[18px] transition duration-300 ease-in-out hover:scale-110`}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div
              onAnimationEnd={() => setIsAnimation(false)}
              className={`${
                feedBackStates.dark === "dark" ? "bg-black" : "bg-white"
              } rounded-[16px] md:rounded-[24px] w-[${
                screen && screen.width !== undefined && screen.width - 32
              }px] h-[275px] md:w-[646px] md:h-[353px] md:p-[40px] flex flex-col items-center lg:items-start justify-start ${
                isAnimation === true && "animated-div"
              }`}
            >
              <div className="flex items-center justify-center lg:justify-start lg:gap-[18px] flex-col lg:flex-row">
                <img className="w-[84px] h-[84px]" src={contactInfo} alt="" />
                <div>
                  <h2
                    className={`${
                      feedBackStates.dark === "dark"
                        ? "text-white"
                        : "text-black"
                    } text-[20px] text-center lg:text-[32px] font-bold lg:text-left`}
                  >
                    Contact Information
                  </h2>
                  <h4
                    className={`${
                      feedBackStates.dark === "dark"
                        ? "text-white"
                        : "text-black"
                    } text-[14px] text-center lg:text-[20px] max-w-[296px] lg:max-w-[420px] truncate whitespace-nowrap lg:whitespace-normal lg:text-left`}
                  >
                    We would like to know your contact information
                  </h4>
                </div>
              </div>
              <div
                onClick={() => {
                  setIsSubmitPage(false);
                  setIsAnimation(true);
                }}
                className={`mt-[10px] bg-[${feedBackStates.themeColor}] lg:mt-[35px] border-[1px] h-[32px] py-[8px] lg:py-0 lg:h-[36px] px-[12px] border-slate-300 cursor-pointer rounded-full flex items-center justify-center gap-1 transition duration-300 ease-in-out hover:scale-110`}
              >
                <BiChevronLeft size={screen && screen.width < 600 ? 20 : 25} />
                <h3 className="text-[13px] lg:text-[20px]">
                  Change your feedback
                </h3>
              </div>

              <form
                action=""
                onSubmit={(e) => {
                  e.preventDefault();
                  dispatch(
                    SET_USER_RESPONSE({
                      name,
                      number,
                      email,
                      feedback: "",
                      answers: feedBackStates.userResponse.answers,
                    })
                  );
                  handleSubmit();
                }}
              >
                <div className="flex items-center justify-between px-[16px] lg:px-0 w-full mt-[10px] lg:mt-[24px] gap-2">
                  <input
                    className={`${
                      feedBackStates.dark === "dark"
                        ? "bg-gray-700"
                        : "bg-white"
                    } border-[1px] text-[13px] placeholder:text-[13px] lg:text-[20px] lg:placeholder:text-[20px] border-slate-200 w-[48%] h-[32px] lg:h-[53px] px-[8px] lg:px-[24px] py-[4px] lg:py-[16px] rounded-[6px] transition duration-300 ease-in-out hover:scale-110`}
                    placeholder="Enter Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    type="text"
                  />
                  <input
                    className={`${
                      feedBackStates.dark === "dark"
                        ? "bg-gray-700"
                        : "bg-white"
                    } border-[1px] text-[13px] placeholder:text-[13px] lg:text-[20px] lg:placeholder:text-[20px] border-slate-200 w-[48%] h-[32px] lg:h-[53px] px-[8px] lg:px-[24px] py-[4px] lg:py-[16px] rounded-[6px] transition duration-300 ease-in-out hover:scale-110`}
                    placeholder="Enter Contact No."
                    value={number === null ? "" : number}
                    onChange={(e) => {
                      const newValue = e.target.value
                        ? parseInt(e.target.value)
                        : null;
                      setNumber(newValue);
                    }}
                    required
                    type="tel"
                  />
                  <input
                    className={`${
                      feedBackStates.dark === "dark"
                        ? "bg-gray-700"
                        : "bg-white"
                    } border-[1px] text-[13px] placeholder:text-[13px] lg:text-[20px] lg:placeholder:text-[20px] border-slate-200 w-full h-[32px] lg:h-[53px] px-[8px] lg:px-[24px] py-[4px] lg:py-[16px] rounded-[6px] transition duration-300 ease-in-out hover:scale-110`}
                    placeholder="Enter Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    type="email"
                  />
                </div>
                <button
                  type="submit"
                  className={`${
                    feedBackStates.dark === "dark"
                      ? `bg-[${feedBackStates.themeColor}] text-black`
                      : "bg-black text-white"
                  } flex items-center justify-center rounded-[6px] cursor-pointer mx-auto w-[calc(100%-32px)] lg:w-[100px] h-[32px] lg:h-[40px] px-[24px] py-[8px] mt-[12px] lg:mt-[18px] transition duration-300 ease-in-out hover:scale-110`}
                >
                  Submit
                </button>
              </form>
            </div>
          )}
        </div>
      ) : (
        <div
          onAnimationEnd={() => setIsAnimation(false)}
          className={`${
            feedBackStates.dark === "dark" ? `bg-black` : "bg-white"
          } rounded-[16px] md:rounded-[24px] w-[${
            screen && screen.width !== undefined && screen.width - 32
          }px] p-[16px] h-[254px] md:w-[646px] md:h-[353px] md:p-[40px] flex flex-col items-center justify-start ${
            isAnimation === true && "animated-div"
          }`}
        >
          <img
            className="w-[212px] h-[142px] lg:w-[302px] lg:h-[202px] lg:-mt-[20px]"
            src={Thankyou}
            alt=""
          />
          <h2
            className={`${
              feedBackStates.dark === "dark" ? "text-white" : "text-black"
            } text-[16px] leading-[20px] lg:text-[20px] lg:leading-[28px] max-w-[296px] text-center lg:min-w-[400px]`}
          >
            We value and appreciate your compliments, suggestions or complaints
            in order to improve our services and the way we communicate
          </h2>
        </div>
      )}

      <div className="fixed bottom-0 left-0 right-0 z-10 w-full h-[220px] md:h-[150px] flex items-center justify-between px-[60px] flex-col-reverse py-[16px] md:py-0 md:flex-row">
        <div className="flex items-center justify-center gap-1">
          <h3 className="text-white font-semibold text-[13px] lg:mt-[4px]">
            Feedback by |{" "}
          </h3>
          <img src={watermark} alt="" />
        </div>
        <button
          onClick={() => {
            dispatch(SET_ITERATOR(0));
            setIsAnimation(true);
            setIsRemarksPage(false);
            setIsSubmitPage(false);
            setIsReasonOpen(false);
            setIsThankYouPage(false);
            setName("");
            setNumber(null);
            setEmail("");
          }}
          className="bg-white w-[122px] h-[36px] md:w-[164px] md:h-[46px] md:px-4 md:py-2 rounded-full flex items-center justify-center gap-1 transition duration-300 ease-in-out hover:scale-110"
        >
          <MdRestartAlt size={screen && screen.width < 600 ? 20 : 28} />
          <h3 className="text-[14px] md:text-[20px] text-black">Start Again</h3>
        </button>
      </div>
    </div>
  );
};

export default FeedbackPage;
