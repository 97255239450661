import axios from "axios";
import React, { useEffect, useState } from "react";
import FeedbackPage from "./pages/FeedbackPage";
import { useSelector, useDispatch } from "react-redux";
import { SET_DATA, SET_ROUTE, SET_TOKEN } from "./redux/reducer";
import { RootState } from "./redux/store";
import LoginPage from "./pages/LoginPage";
import Cookies from "js-cookie";

function App() {
  const dispatch = useDispatch();
  const route = useSelector((state: RootState) => state.feedbackReducer.route);
  useEffect(() => {
    // Set initial route to login page
    dispatch(SET_ROUTE(""));

    // Check for access token
    const accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      // If token exists, redirect to FeedbackPage
      dispatch(SET_ROUTE("/FeedbackPage"));
    }
  }, [dispatch]);

  const handleLogout = () => {
    // Clear local storage
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");

    // Clear cookies
    Cookies.remove("token");

    // Reset Redux state
    dispatch(SET_TOKEN(""));
    dispatch(SET_ROUTE(""));
  };

  return (
    <div className="w-full h-screen">
      {route === "" && <LoginPage />}
      {route === "/FeedbackPage" && <FeedbackPage onLogout={handleLogout} />}
    </div>
  );
}

export default App;
