import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface FeedBackState {
  data: {
    title: string;
    themeColor: string;
    themeMode: string;
    logoHeight: string;
    logoWidth: string;
    background: Array<{
      lastModified: number;
      lastModifiedDate: string;
      name: string;
      size: number;
      type: string;
      url: string;
      webkitRelativePath: string;
    }>;
    logo: Array<{
      lastModified: number;
      lastModifiedDate: string;
      name: string;
      size: number;
      type: string;
      url: string;
      webkitRelativePath: string;
    }>;
    questions: Array<{
      title: string;
      question: string;
      icon: Array<{
        lastModified: number;
        lastModifiedDate: string;
        name: string;
        size: number;
        type: string;
        url: string;
        webkitRelativePath: string;
      }>;
      reasons: Array<{
        label: string;
        value: string;
      }>;
      type: string;
    }>;
  } | null;
  iterator: number;
  route: string;
  token: string;
  dark: string;
  themeColor: string;
  userResponse: {
    code: string;
    createdBy: string;
    updatedBy: string;
    isActive: boolean;
    isDeleted: boolean;
    feedbackApp: string;
    name: string;
    number: number|null;
    email: string;
    feedback: string;
    answers: Array<{
      question: string;
      answer: string;
      reason: string;
      remarks: string;
    }>;
    createdAt: string;
    updatedAt: string;
    __v: 0;
  };
}

const initialState: FeedBackState = {
  data: null,
  iterator: 0,
  route: "",
  token: "",
  dark: "light",
  themeColor: "#F9C747",
  userResponse: {
    code: "FD-230612100241992-01",
    createdBy: "01",
    updatedBy: "01",
    isActive: true,
    isDeleted: false,
    feedbackApp: "test",
    name: "",
    number: 0,
    email: "",
    feedback: "",
    answers: [],
    createdAt: "2023-06-12T10:02:41.993Z",
    updatedAt: "2023-06-12T10:02:41.993Z",
    __v: 0,
  },
};

export const feedbackReducer = createSlice({
  name: "feedback",
  initialState,
  reducers: {
    SET_DATA: (state, action: PayloadAction<FeedBackState['data']>) => {
      state.data = action.payload;
    },
    SET_ITERATOR: (state, action: PayloadAction<number>) => {
      state.iterator = action.payload;
    },
    SET_USER_RESPONSE: (
      state,
      action: PayloadAction<{
        name: string;
        number: number|null;
        email: string;
        feedback: string;
        answers: Array<{
          question: string;
          answer: string;
          reason: string;
          remarks: string;
        }>;
      }>
    ) => {
      state.userResponse = {
        ...state.userResponse,
        ...action.payload
      };
    },
    SET_ROUTE: (state, action: PayloadAction<string>) => {
      state.route = action.payload;
    },
    SET_TOKEN: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    SET_DARK: (state, action: PayloadAction<string>) => {
      state.dark = action.payload;
    },
    SET_THEMECOLOR: (state, action: PayloadAction<string>) => {
      state.themeColor = action.payload;
    },
    SET_QUESTION_ANSWERS: (
      state,
      action: PayloadAction<{
        question: string;
        answer: string;
        index: number;
      }>
    ) => {
      state.userResponse.answers[action.payload.index] = {
        ...state.userResponse.answers[action.payload.index],
        question: action.payload.question,
        answer: action.payload.answer,
      };
    },
    SET_REASONS: (
      state,
      action: PayloadAction<{
        reason: string;
        index: number;
      }>
    ) => {
      state.userResponse.answers[action.payload.index] = {
        ...state.userResponse.answers[action.payload.index],
        reason: action.payload.reason,
      };
    },
  },
});

export const {
  SET_DATA,
  SET_ITERATOR,
  SET_QUESTION_ANSWERS,
  SET_ROUTE,
  SET_USER_RESPONSE,
  SET_TOKEN,
  SET_DARK,
  SET_THEMECOLOR,
  SET_REASONS,
} = feedbackReducer.actions;

export default feedbackReducer.reducer;
